<template>
  <div class="loginPage">
    <div class="loginView">
      <div class="logo">诺为欣麻醉软件</div>
        <div class="tabView left" v-show="type != 3">
          <button @click="type = 1" :class="type == 1 ? 'active' : ''">
            验证码登录
          </button>
          <button @click="type = 2" :class="type == 2 ? 'active' : ''">
            密码登录
          </button>
        </div>
        <component :is="userComponent" @changeType="changeType" />
    </div>
  </div>
</template>
<script>
import { defineAsyncComponent } from "vue";
export default {
  name: "login",
  data() {
    return {
      type: 1,
    };
  },
  created(){
    this.type = this.$route.query.type || 1;
  },
  methods: {
    changeType(type) {
      this.type = type;
    },
  },
  computed: {
    userComponent() {
      let { type } = this;
      if (type == 2) return defineAsyncComponent((_) => import("./modules/password"));
      if (type == 3) return defineAsyncComponent((_) => import("./modules/modify"));
      return defineAsyncComponent((_) => import("./modules/vercode"));
    },
  },
};
</script>
<style lang="scss">
@import "index.scss";
</style>